import DataCard from "@component/DataCard"
import dayjs from 'dayjs'
import { useGetSalesReport } from '@feature/ReportOfTheDay/hooks/useSaleReport'
import Box from '@component/Box'
import Typography from '@component/Typography'
import { currencyFormat } from '@hook/useNumberFormat'
import { useURLParams } from '@hook/useURLParams'

const SummaryClosures = () => {
    const { getParameterGroup, handleActionParameter } = useURLParams()
    const { startDate=null } = getParameterGroup(["startDate"])
    const { totalCurrency, totalSale, totalPercentage, isLoading } = useGetSalesReport({ endDate: startDate, initDate: startDate })
    const Summary = () => {
        return(
            <>
                <Box display={'flex'} flexDirection={'column'} >
                    <Typography textAlign={'center'} fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >
                        Resumen del dia
                    </Typography>
                    <Typography textAlign={'center'} fontWeight={'bold'} fontSize={'1.3rem'} sx={{ color: (theme) => theme.palette.success.main }} >
                        $ {currencyFormat(totalSale)}
                    </Typography>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={2} >
                        <Typography fontWeight={'500'} fontSize={'1rem'} > $ {currencyFormat(totalCurrency)} </Typography>
                        <Typography fontWeight={'500'} fontSize={'1rem'} > {totalPercentage} % </Typography>
                    </Box>
                </Box>
            </>
        )
    }
    return(
        <>
            <DataCard
                headerVariant="date"
                onChangeDate={(params) => handleActionParameter("startDate", dayjs(params).format("MM-DD-YYYY"))}
                slots={{
                    cardBody: <Summary />
                }}
                loading={isLoading}
            />
        </>
    )
}

export default SummaryClosures