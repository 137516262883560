import DataGrid, { GridColDef, GridEventListener } from "@component/DataGrid"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import Grid from "@component/Grid"
import List, { ListItem, ListItemIcon, ListItemText } from "@component/List"
import { useGetCustomer } from "../hooks/useCustomer"
import UpdateAdvanceValue from "./UpdateAdvanceValue"
import { useURLParams } from "@hook/useURLParams"
import IconButton from "@component/IconButton"
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import Tooltip from "@component/Tooltip"
import ReturnAdvance from "./ReturnAdvance"

const ListOfAdvances = () => {
    const { handleParameterGroups, delQueries, addQuery } = useURLParams()
    const { advances, customer, totalAdvances } = useGetCustomer()
    const { t } = useTranslation()
    const columns:GridColDef[] = [
        { flex: 1, field: "box", headerName: "Caja", renderCell: ({ row }) => `${t(row?.box??"")}` },
        { maxWidth: 140, minWidth: 140, field: "amount", headerName: "Valor", renderCell: ({ row }) => `$ ${ currencyFormat(row.amount) }` },
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => `${ dayjs(row.createdAt).format("DD-MM-YYYY HH:mm A") }`},
    ]

    const handleCellClick:GridEventListener<"cellClick"> = ({ field, id }) => {
        if( field === "amount" ){
            handleParameterGroups(['field', '_id'], [field, id as string])
        }else{
            delQueries(['field', 'ref'])
        }
    }

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }} >
                        <ListItem>
                            <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary={`$ ${currencyFormat(totalAdvances)}`} secondary="Total en anticipos" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Tooltip title="Devolver monto" >
                                    <IconButton onClick={() => addQuery({ modal: "return_advance", ref: (customer?._id??"") })} >
                                        <CurrencyExchangeRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary={`$ ${currencyFormat((customer?.paymentAvailable??0))}`} secondary="Anticipo disponible" />
                        </ListItem>
                    </List>
                </Grid>
                <Grid xs={12} >
                    <DataGrid
                        onCellClick={handleCellClick}
                        columns={columns}
                        rows={advances}
                    />
                </Grid>
            </Grid>
            <UpdateAdvanceValue />
            <ReturnAdvance maxAmount={(customer?.paymentAvailable??0)} />
        </>
    )
}

export default ListOfAdvances