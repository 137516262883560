import Grid from "@component/Grid"
import Title from "@component/Title"
import BusinessAssets from "./BusinessAssets"
import BusinessLiabilities from "./BusinessLiabilities"
import BusinessEquity from "./BusinessEquity"
import SummaryClosures from "./SummaryClosures"
import DetailedAssets from "./DetailedAssets"
import DetailedLiabilities from "./DetailedLiabilities"
import VariableExpends from "./VariableExpends"
import FixedExpends from "./FixedExpends"
import MonthlySalesReport from "./MonthlySalesReport"
import SummaryDashboardFinancialStatus from "./SummaryDashboardFinancialStatus"

const HomeAdmin = () => {
    return(
        <Grid container spacing={2} >
            <Grid xs={12} >
                <Title text="Panel" />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <BusinessAssets />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <BusinessLiabilities />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <BusinessEquity />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <SummaryClosures />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <DetailedAssets />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <DetailedLiabilities />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <VariableExpends />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <FixedExpends />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3} >
                <MonthlySalesReport />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={9} >
                <SummaryDashboardFinancialStatus />
            </Grid>
        </Grid>
    )
}

export default HomeAdmin