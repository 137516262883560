import Card from "@component/Card"
import Divider from "@component/Divider"
import List, { ListItem, ListItemText } from "@component/List"
import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import React from "react"
import { useGetBusinessFinancialStatement } from "@feature/Business/hooks/useBusiness"
import { useTranslation } from "react-i18next"
import { useURLParams } from "@hook/useURLParams"

const DetailedAssets = () => {
    const { t } = useTranslation()
    const { getParameterGroup } = useURLParams()
    const { startDate="" } = getParameterGroup(["startDate"])
    const { financialStatement } = useGetBusinessFinancialStatement({ startDate })
    const boxes = financialStatement?.boxes?.map((n) => ({ primaryText: `${t(n.box)} ${n?.bank ? `- ${n.bank}` : ``}`, secondaryText: "", value: (n?.total??0) }))||[]

    return(
        <>
            <Card sx={{ mb: 1 }} variant="elevation" elevation={4} >
                <Typography fontSize={'1rem'} fontWeight={'bold'} textTransform={'uppercase'} >Activos</Typography>
                <List sx={{ padding: 0 }} >
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.success.main }} primary={"Inventario"} secondary={`$ ${currencyFormat(financialStatement?.inventory??0, true)}`} />
                    </ListItem>
                    <Divider />
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.success.main }} primary={"Cuentas por cobrar (CxC)"} secondary={`$ ${currencyFormat(financialStatement?.cxc??0, true)}`} />
                    </ListItem>
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.success.main }} primary={"Anticipo a proveedores"} secondary={`$ ${currencyFormat((financialStatement?.advances??0), true)}`} />
                    </ListItem>
                    <Divider />
                    {boxes?.map((n, index) => {
                        return(
                            <React.Fragment key={index} >
                                <ListItem sx={{ padding: 0 }} >
                                    <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.success.main }} primary={n.primaryText} secondary={`$ ${currencyFormat(n?.value??0, true)}`} />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        )
                    })}
                </List>
                <Divider />
            </Card>
        </>
    )
}

export default DetailedAssets