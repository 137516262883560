import React from 'react'
import { styled } from "@mui/material/styles"
import { GridColDef } from "@mui/x-data-grid"
import Box from "@component/Box"
import DataGrid from "@component/DataGrid"
import Typography from "@component/Typography"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { calcProfitPercentage, currencyFormat } from "../../../hooks/useNumberFormat"
import { ListProductsInvoice } from '../../../interfaces/PointOfSale'
import { useGetProductsForPointOfSaleQuery } from '../../../api/querys/Product'
import { loadSalesList, removeProductFromList, showPOSProductWindow, uploadInvoiceIdentifiers } from '../services/PointOfSaleSlice'
import { readCookie } from '../../../services/parseData'
import { IconButton } from '@mui/material'
import DeleteForeverRounded from '@mui/icons-material/DeleteForeverRounded';

const CustomDataGrid = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': { display: 'none' },
    '& .MuiDataGrid-footerContainer': { display: 'none' },
    "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
}))

interface Props {
    setLoadProductToEdit : React.Dispatch<React.SetStateAction<ListProductsInvoice | null>>
}

const PointOfSaleItem = ({setLoadProductToEdit}:Props) => {
    const { data:ProductsForPointOfSaleQuery } = useGetProductsForPointOfSaleQuery()
    const products = ProductsForPointOfSaleQuery?.payload?.productsForPointOfSale?.results??[]
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productList } = PointOfSaleState
    const columns:Array<GridColDef> = [
        { flex: 1, field: "name", renderCell: ({row}) => {
            return(
                <Box id={`item-${row._id}`} display={'flex'} flexDirection={'column'} width={1}>
                    <Box>
                        <Typography fontSize={'.9rem'} fontWeight={'bold'} > {row.name} </Typography>
                    </Box>
                    <Box display={'flex'} width={1} >
                        <Box display={'flex'} gap={2} width={1} >
                            <Typography color={'green'} fontWeight={'bold'} > {row.quantity}x </Typography>
                            <Typography>$ { currencyFormat((row.salePrice)) } </Typography>
                            <Typography> { calcProfitPercentage( ( (row.quantity*row.salePrice)-(row.quantity*row.costPrice) ), (row.quantity*row.salePrice) ) } % </Typography>
                        </Box>
                        <Box>
                            <Typography>$ { currencyFormat(row.quantity*row.salePrice) } </Typography>
                        </Box>
                    </Box>
                </Box>
            )
        } },
        { maxWidth: 50, minWidth: 50, field: "delItem", align:'left', renderCell: ({row}) => {
            return(
                <>
                    <IconButton color="error" size='small' onClick={() => dispatch(removeProductFromList(row._id))} >
                        <DeleteForeverRounded />
                    </IconButton>
                </>
            )
        } }
    ]

    const loadProductToEdit = (productToEdit:ListProductsInvoice, field:string) => {
        if( field !== 'delItem'){
            const productIndex = products.findIndex(n => n._id === productToEdit._id)
            if( productIndex >= 0 ){
                let product = products[productIndex]
                dispatch(showPOSProductWindow({ isOpen: true, productSelected: {...product} }))
                setLoadProductToEdit(productToEdit)
            }
        }
    }

    React.useEffect(() => {
        const cookieProductList = readCookie("productList")
        const cookieInvoiceIdentifiers = readCookie("invoiceIdentifiers")
        if( Boolean(cookieProductList) ){
            const productList = JSON.parse(cookieProductList!)
            dispatch(loadSalesList(productList))
        }
        if( Boolean(cookieInvoiceIdentifiers) ){
            const invoiceIdentifiers = JSON.parse(cookieInvoiceIdentifiers!)
            dispatch(uploadInvoiceIdentifiers({ _id: invoiceIdentifiers._id, consecutive: invoiceIdentifiers.consecutive }))
        }
    },[dispatch])
    return(
        <Box mt={2} sx={{ overflowY: "auto", maxHeight: "65vh" }} >
            <CustomDataGrid
                onCellClick={({ row, field }) => loadProductToEdit(row, field)}
                rowHeight={50}
                hideFooterPagination
                columns={columns}
                rows={productList}
            />
        </Box>
    )
}

export default PointOfSaleItem