import React from 'react';
import Dialog, { DialogContent } from '../../components/Dialog';
import Grid from '../../components/Grid';
import Toolbar from '../../components/Toolbar';
import AppBarPOS from './components/AppBarPOS';
import WindowProductPOS from './components/WindowProductPOS';
import { openPointOfSaleWindow } from './services/PointOfSaleSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/state';
import PointOfSaleItem from './components/PointOfSaleItem';
import PointOfSaleTotalize from './components/PointOfSaleTotalize';
import PointOfSaleUserSales from './components/PointOfSaleUserSales';
import PointOfSaleCustomer from './components/PointOfSaleCustomer';
import dayjs from 'dayjs';
import ProviderKeyboardShortcutsPOS from './components/ProviderKeyboardShortcutsPOS';
import PointOfSaleButtons from './components/PointOfSaleButtons';
import AlertClearAreaDeBilling from './components/AlertClearAreaDeBilling';
import TemplateInvoice from '../Invoice/components/TemplateInvoice';
import Box from '../../components/Box';
import SearchBarPOS from './components/SearchBarPOS';
import { ListProductsInvoice } from '../../interfaces/PointOfSale';
import CreateFastCustomer from './components/CreateFastCustomer';
import CreateFastProduct from './components/CreateFastProduct';
import PreviewInvoice from './components/PreviewInvoice';
import SelectPaymentMethod from '../Invoice/components/SelectPaymentMethod';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import LockComponent from '../../components/LockComponent';
import TemplateLargeInvoice from '../Invoice/components/TemplateLargeInvoice';
import DrawerCashier from './components/DrawerCashier';
import NoteNoteCredit from './components/NoteNoteCredit';
import { writeCookie } from '@service/parseData';
import CreateExpense from '@feature/Expenses/components/CreateExpense';
import { useCashierReport } from '@feature/MovementsCashier/hooks/useMovementsCashier';
import { useGetSessionDataQuery } from '@query/Users';

const PointOfSale = () => {
    const { PointOfSale } = useBusinessPermissions()
    const dispatch = useAppDispatch();
    const { data } = useGetSessionDataQuery()
    const invoiceRef = React.useRef<HTMLDivElement | null>(null);

    const { InvoiceState, AppState, PointOfSaleState } = useAppSelector(({ InvoiceState, AppState, PointOfSaleState }) => ({ InvoiceState, AppState, PointOfSaleState }));
    const { invoiceMode } = AppState
    const { invoiceValues } = InvoiceState;
    const { isOpenPointOfSale, productList, customer, lastBill, invoiceIdentifiers, registerCreditNote } = PointOfSaleState;
    const { summary } = useCashierReport({ startDate: dayjs().format("MM-DD-YYYY"), endDate: dayjs().format("MM-DD-YYYY"), cashierId: data?.payload?._id??"" })

    const [editProduct, setEditProduct] =
        React.useState<ListProductsInvoice | null>(null);



    React.useEffect(() => {
        if (productList.length >= 1) {
            writeCookie({ key: "productList", value: JSON.stringify(productList), expire: dayjs().startOf('day').add(1, 'day').toDate() })
        }
        if (customer?._id !== undefined) {
            writeCookie({ key: "customer", value: JSON.stringify(customer), expire: dayjs().startOf('day').add(1, 'day').toDate() })
        }
    }, [productList, customer]);

    React.useEffect(() => {
        if (Boolean(invoiceIdentifiers?._id)) {
            writeCookie({ key: "invoiceIdentifiers", value: JSON.stringify(invoiceIdentifiers), expire: dayjs().startOf('day').add(1, 'day').toDate() })
        }
    }, [invoiceIdentifiers])

    return (
        <Dialog open={isOpenPointOfSale} onClose={() => dispatch(openPointOfSaleWindow(false))} fullScreen >
            {PointOfSale.select_invoice_payment_method &&
                <SelectPaymentMethod invoiceId={invoiceValues?._id} />
            }
            <AppBarPOS />
            <DialogContent sx={{ position: 'relative' }}>
                {PointOfSale.lock_module ?
                    <>
                        {isOpenPointOfSale && (
                            <ProviderKeyboardShortcutsPOS>
                                {PointOfSale.select_invoice_payment_method &&
                                    <DrawerCashier />
                                }
                                <Toolbar />

                                <Grid container spacing={2} sx={{ height: 'calc(100% - 60px)' }} pb={5} >
                                    <Grid xs={12} sm={12} md={8} lg={8} sx={{ height: '100%' }} >
                                        { !registerCreditNote &&
                                            <SearchBarPOS />
                                        }
                                        <PointOfSaleItem setLoadProductToEdit={setEditProduct} />
                                        <Box my={2} sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }} >
                                            {registerCreditNote ?
                                                <NoteNoteCredit />
                                                :
                                                <PointOfSaleUserSales />
                                            }
                                            <Toolbar />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4} sx={{
                                        height: '100%',
                                        display: { xs: "none", sm: "none", md: "block", lg: "block" }
                                    }} >
                                        <PointOfSaleCustomer />
                                        <PointOfSaleTotalize />
                                        {registerCreditNote ?
                                            <NoteNoteCredit />
                                            :
                                            <PointOfSaleUserSales />
                                        }
                                    </Grid>
                                </Grid>

                                <Box position={'fixed'} bottom={0} left={0} width={1} >
                                    <PointOfSaleButtons invoiceElement={invoiceRef} />
                                </Box>
                                {invoiceMode === "pos" ?
                                    <TemplateInvoice
                                        sx={{ displayPrint: 'block', display: 'none' }}
                                        ref={invoiceRef}
                                        {...lastBill}
                                    />
                                    :
                                    <TemplateLargeInvoice
                                        sx={{ displayPrint: 'block', display: 'none' }}
                                        ref={invoiceRef}
                                        {...lastBill}
                                    />
                                }
                                <WindowProductPOS editProduct={editProduct} setLoadProductToEdit={setEditProduct} />
                                <AlertClearAreaDeBilling />
                                <CreateExpense maxAmount={summary?.inCash??0} box="cashierBox" conceptExpense="cashExpense" />
                                {PointOfSale.create_customer_from_point_of_sale &&
                                    <CreateFastCustomer />
                                }
                                {PointOfSale.create_product_from_point_of_sale &&
                                    <CreateFastProduct />
                                }
                                <PreviewInvoice />
                            </ProviderKeyboardShortcutsPOS>
                        )}
                    </>
                    :
                    <LockComponent />
                }
            </DialogContent>
        </Dialog>
    );
};

export default PointOfSale;
