import Box from '@component/Box';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetSalesReportQuery } from '../../../api/querys/PointOfSale';
import DataGrid from '../../../components/DataGrid';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import Routes from '../../../services/routes';
import { resetFilters } from '../services/ReportOfTheDaySlice';
import { SaleReportResponse } from '@interface/PointOfSale'

const TableReports = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ReportOfTheDayState } = useAppSelector(({ ReportOfTheDayState }) => ({
    ReportOfTheDayState,
  }));
  const { initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupById, documentNumber } = ReportOfTheDayState;
  const { data } = useGetSalesReportQuery({ documentNumber, initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupById, });
  const reports = data!?.payload?.report;
  const sales = data?.payload?.sales??[] as SaleReportResponse['payload']['sales']
  const salesTotal = sales.reduce((accumulator, params) => accumulator+params.total ,0)
  const columns: Array<GridColDef> = [
    {
      field: 'customer',
      headerName: t('customer')!,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => <> {t(row.customer)} </>,
    },
    { field: 'consecutive', headerName: '#FAC', minWidth: 100 },
    {
      field: 'total',
      headerName: t('sale')!,
      minWidth: 100,
      renderCell: ({ row }) => <>$ {currencyFormat(row.total)} </>,
    },
    {
      field: 'totalProfitCurrency',
      headerName: '$',
      headerAlign: 'center',
      minWidth: 100,
      renderCell: ({ row }) => (
        <>$ {currencyFormat(row.totalProfitCurrency)} </>
      ),
    },
    {
      field: 'totalProfitPercentage',
      headerName: '%',
      headerAlign: 'center',
      maxWidth: 70,
      minWidth: 70,
      renderCell: ({ row }) => (
        <>{currencyFormat(row.totalProfitPercentage)}%</>
      ),
    },
    {
      field: 'typePayment',
      headerName: t('paymentType')!,
      align: 'center',
      renderCell: ({ row }) => <> {t(row.typePayment)} </>,
    },
    {
      maxWidth: 150,
      minWidth: 150,
      field: 'createdAt',
      headerName: t('Registro')!,
      align: 'center',
      renderCell: ({ row }) => <> {dayjs(row.createdAt).format("DD-MM-YYYY HH:mm A")} </>,
    },
    { field: 'seller', headerName: t('seller')! },
  ];
  
  const handleCellClick = ({ id }: GridCellParams) => {
    const rowId = id as string;
    dispatch(resetFilters())
    navigate(Routes.indexInvoice(rowId));
  };

  const Total = (params:{total:number}) => {
    const { total } = params
    return(
      <Box position={'absolute'} left={"16px"} fontWeight={'bold'} textTransform={'uppercase'} >
        Total: $ {currencyFormat(total)}
      </Box>
    )
  }
  return (
    <>
      <DataGrid
        FooterComponent={<Total total={salesTotal} />}
        rows={reports || []}
        columns={columns}
        onCellClick={handleCellClick}
      />
    </>
  );
};

export default TableReports;
