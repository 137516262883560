import React from 'react'
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded"
import List, { ListItem, ListItemIcon, ListItemText } from "@component/List"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { readCookie } from '../../../services/parseData'
import { selectCustomerForInvoice } from '../services/PointOfSaleSlice'
import DeleteForeverRounded from '@mui/icons-material/DeleteForeverRounded'
import IconButton from '../../../components/IconButton'
import CustomerAdvance from '@feature/Customer/components/CustomerAdvance'
import { styled } from '@mui/material/styles'

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    [theme.breakpoints.down("sm")] : {
        display: 'none',
    }
}))

const ListItemIconContainerStyled = styled(ListItemIcon)(({ theme }) => ({
    [theme.breakpoints.down("sm")] : {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiButtonBase-root svg' : {
            fontSize: '1.2rem'
        }
    }
}))

const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
    [theme.breakpoints.down("sm")] : {
        maxWidth: 100,
        '& .MuiTypography-root': {
            fontSize: '1rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
        }
    }
}))

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.down("sm")] : {
        color: theme.palette.primary.contrastText,
    }
}))

const PointOfSaleCustomer = () => {
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { customer } = PointOfSaleState
    const dispatch = useAppDispatch() 
    React.useEffect(() => {
        const cookieCustomerString = readCookie("customer")
        if( Boolean(cookieCustomerString) ){
            const cookieCustomer = JSON.parse(cookieCustomerString!)
            dispatch(selectCustomerForInvoice(cookieCustomer))
        }
    },[dispatch])
    return(
        <>
            { customer?._id !== undefined &&
                <List sx={{ padding: 0 }} >
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemIconStyled>
                            <AccountCircleRounded sx={{ fontSize: "2.5rem" }} />
                        </ListItemIconStyled>
                        <ListItemTextStyled
                            primary={customer?.name??""}
                            secondary={customer?.address??""}
                        />
                        <ListItemIconContainerStyled>
                            <IconButtonStyled size='small' color='error' onClick={() => dispatch(selectCustomerForInvoice(null))} >
                                <DeleteForeverRounded />
                            </IconButtonStyled>
                        </ListItemIconContainerStyled>
                    </ListItem>
                </List>
            }
            <CustomerAdvance />
        </>
    )
}

export default PointOfSaleCustomer