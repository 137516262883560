import AppBar from "@component/AppBar"
import Box from "@component/Box"
import Toolbar from "@component/Toolbar"
import Typography from "@component/Typography"
import PointOfSaleCustomer from "./PointOfSaleCustomer"
import PointOfSaleTotalize from "./PointOfSaleTotalize"
import { useAppSelector } from "@hook/state"


const AppBarPOS = () => {

    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { invoiceIdentifiers } = PointOfSaleState

    return(
        <AppBar elevation={0} sx={ Boolean(invoiceIdentifiers._id) ? { backgroundColor: (theme) => theme.palette.warning.dark } : {} } >
            <Toolbar sx={{ px: 1 }} >
                <Box display={'flex'} flexGrow={1} alignItems="center" >
                    <Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }} >
                        <PointOfSaleCustomer />
                        {Boolean(invoiceIdentifiers._id) &&
                            <Box ml={2} >
                                <Typography fontWeight={'bold'} textTransform={'uppercase'} >#R{invoiceIdentifiers.consecutive} </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} >
                    <Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
                        <PointOfSaleTotalize />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default AppBarPOS