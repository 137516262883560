import { calcProfitPercentage } from "@hook/useNumberFormat"
import { SaleReportResponse } from "@interface/PointOfSale"
import { useGetSalesReportQuery } from "@query/PointOfSale"

interface useGetSalesReportProps {
    initDate: string,
    endDate: string
} 
export const useGetSalesReport = ({ initDate, endDate }:useGetSalesReportProps) => {
    const { isLoading, data } = useGetSalesReportQuery({ initDate, endDate }, { refetchOnMountOrArgChange: true })
    const results = data?.payload??{} as SaleReportResponse['payload']
    const sales = data?.payload?.sales??[] as SaleReportResponse['payload']['sales']
    const totalSale = sales.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0 )
    const totalCurrency = sales.reduce((accumulator, currentValue) => accumulator + currentValue.totalProfitCurrency, 0 )

    return {
        results,
        sales,
        totalSale,
        totalCurrency,
        totalPercentage: calcProfitPercentage(totalCurrency, totalSale),
        isLoading
    }
}