import { mutationConfig } from './../splitApi';
import type { ArchiveInvoiceResponse, CloseOfTheDayResponse, CreditsResponse, CustomerPurchaseTrackingResponse, DashboardInformationResponse, getSalesReportProps, InvoicePaymentResponse, InvoiceProps, InvoiceResponse, PointOfSaleMinorExpensesProps, PointOfSaleMinorExpensesResponse, PointOfSaleMinorShoppingProps, PointOfSaleMinorShoppingResponse, PreviousDaysCreditsResponse, ProductPurchaseTrackingResponse, RecordInvoice, RegisterMinorExpensesResponse, RegisterMinorShoppingResponse, SaleReportResponse } from '../../interfaces/PointOfSale.d';
import { api } from '../splitApi';
import { DateRangeFilter } from '@interface/App';
import { FixedAndVariableExpensesAsyncResponse } from '@interface/Business';
import dayjs from 'dayjs';

interface DateFilter {
    startDate: string
    endDate: string
}

export const PointOfSale = api.injectEndpoints({
    endpoints: (build) => ({
        getMinorExpensesByBusiness: build.query<PointOfSaleMinorExpensesResponse, string>({
            query: (initDate) => ({
                url: '/get/minor/expenses',
                params: { initDate }
            }),
            providesTags: ['MinorExpense'],
        }),
        getMinorShoppingByBusiness: build.query<PointOfSaleMinorShoppingResponse, { initDate: string }>({
            query: ({ initDate }) => ({
                url: '/get/minor/shopping',
                params: { initDate }
            }),
            providesTags: ['MinorShopping'],
        }),
        getCustomerPurchaseTracking: build.query<
            CustomerPurchaseTrackingResponse,
            {
                startDate: string;
                endDate: string;
                branch: string;
                businessType: string;
                zone: string;
                customerId: string;
            }
        >({
            query: ({
                startDate,
                endDate,
                branch,
                businessType,
                zone,
                customerId,
            }) => ({
                url: `/get/customer/purchase/tracking`,
                params: { startDate, endDate, branch, businessType, zone, customerId },
            }),
        }),
        getProductPurchaseTracking: build.query<
            ProductPurchaseTrackingResponse,
            string
        >({
            query: (invoiceCollection) => ({
                url: `/get/product/purchase/tracking`,
                params: { invoiceCollection },
            }),
        }),
        getDashboardInformation: build.query<DashboardInformationResponse, void>({
            query: () => ({ url: `/get/dashboard/information` }),
        }),
        registerMinorExpense: build.mutation<RegisterMinorExpensesResponse, PointOfSaleMinorExpensesProps>({
            query(body) {
                return { ...mutationConfig, url: '/register/minor/expense', body };
            },
            invalidatesTags: ['MinorExpense', 'Transfer'],
        }),
        registerMinorShopping: build.mutation<RegisterMinorShoppingResponse, PointOfSaleMinorShoppingProps>({
            query(body) {
                return { ...mutationConfig, url: '/register/minor/shopping', body };
            },
            invalidatesTags: ['MinorShopping', 'Transfer'],
        }),
        archivedMinorExpense: build.mutation<any, { minorExpenseId: string }>({
            query(body) {
                return { ...mutationConfig, url: '/archive/minor/expense', body };
            },
            invalidatesTags: ['MinorExpense'],
        }),
        archivedMinorShopping: build.mutation<any, { minorShoppingId: string }>({
            query(body) {
                return { ...mutationConfig, url: '/archive/minor/shopping', body };
            },
            invalidatesTags: ['MinorShopping'],
        }),
        getClosingOfDay: build.query<CloseOfTheDayResponse, { closingDate: string }>({
            query: ({ closingDate }) => ({
                url: `/get/closing/of/the/day`,
                params: { closingDate },
            }),
            providesTags: ['Invoice'],
        }),
        getPreviousDaysCredits: build.query<PreviousDaysCreditsResponse, { closingDate: string }>({
            query: ({ closingDate }) => ({
                url: `/get/previous/days/credits`,
                params: { closingDate },
            }),
            providesTags: ['Invoice'],
        }),
        getSalesReport: build.query<SaleReportResponse, getSalesReportProps>({
            query: ({ initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupById, documentNumber }) => ({
                url: `/get/sales/report`,
                params: { initDate, endDate, userId, customerId, consecutive: invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupBy: groupById, documentNumber },
            }),
            providesTags: ['Invoice', 'InvoiceWithOutPayment'],
        }),
        getMonthlyReport: build.query<SaleReportResponse, void>({
            query: () => ({
                url: `/get/sales/report`,
                params: { initDate: dayjs().startOf("month").format("MM-DD-YYYY"), endDate: dayjs().format("MM-DD-YYYY") },
            }),
            providesTags: ['Invoice', 'InvoiceWithOutPayment'],
        }),
        getCreditReport: build.query<CreditsResponse, DateRangeFilter & {customerId?: string}>({
            query: ({ startDate, endDate, customerId }) => ({
                url: `/get/credit/report`,
                params: { startDate, endDate, customerId }
            }),
            providesTags: ['Invoice']
        }),
        getInvoiceReportWithoutDefinedPayment: build.query<InvoicePaymentResponse, void>({
            query: () => ({ url: `/get/invoice/report/without/defined/payment` }),
            providesTags: ['InvoiceWithOutPayment']
        }),
        getDeliveryReport: build.query<CreditsResponse, DateFilter>({
            query: ({ endDate, startDate }) => ({
                url: `/get/delivery/report`,
                params: { endDate, startDate }
            }),
            providesTags: ['Invoice']
        }),
        getFiltersForReportsInDateRange: build.query({
            query: ({ initDate, endDate }) => ({
                url: `/get/filters/for/reports/in/date/range`,
                params: {
                    initDate,
                    endDate,
                },
            }),
        }),
        getInvoice: build.query<InvoiceResponse, { invoiceId: string }>({
            query: ({ invoiceId }) => ({
                url: `/get/invoice`,
                params: { invoiceId },
            }),
            providesTags: ['Invoice'],
        }),
        deletePaymentToInvoice: build.mutation<
            any,
            { payment: number; invoiceId: string }
        >({
            query(body) {
                return { ...mutationConfig, url: '/delete/payment/to/invoice', body };
            },
            invalidatesTags: ['Invoice'],
        }),
        addPaymentToInvoice: build.mutation<
            any,
            {
                amount: number | undefined;
                invoiceId: string;
                date: Date;
                wallet: 'cash' | 'consignment';
                bank: string;
            }
        >({
            query(body) {
                return {
                    ...mutationConfig,
                    url: '/add/new/credit/to/the/invoice',
                    body,
                };
            },
            invalidatesTags: ['Invoice'],
        }),
        changePaymentMethod: build.mutation<any, { typePayment: InvoiceProps['typePayment']; invoiceId: string; deliveryName?: InvoiceProps['deliveryName']; bank?: string; creditExpirationDate?: string; observation: string }>({
            query(body) {
                return { ...mutationConfig, url: '/change/bill/payment/method', body };
            },
            async onQueryStarted({ invoiceId }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    PointOfSale.util.updateQueryData('getInvoiceReportWithoutDefinedPayment', undefined, (draft) => {
                        const invoiceIndex = draft.payload.findIndex(n => n._id === invoiceId)
                        if (invoiceIndex >= 0) draft.payload.splice(invoiceIndex, 1)
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },

        }),
        recordSale: build.mutation<InvoiceResponse, RecordInvoice>({
            query(body) {
                return {
                    ...mutationConfig,
                    url: '/register/sale',
                    body,
                };
            },
            invalidatesTags: ['Product'],
        }),
        updateSale: build.mutation<
            InvoiceResponse,
            RecordInvoice & { invoiceId: string }
        >({
            query(body) {
                return {
                    ...mutationConfig,
                    url: '/update/invoice',
                    body,
                };
            },
            invalidatesTags: ['Invoice', 'Product', 'CashWithdrawals'],
        }),
        archiveInvoice: build.mutation<ArchiveInvoiceResponse, { invoiceId: string, bank: string | null, box: string | null }>({
            query(body) {
                return { ...mutationConfig, url: '/archive/invoice', body };
            },
            invalidatesTags: ['Invoice', 'InvoiceWithOutPayment', 'Product'],
        }),
        getFixedAndVariableExpenses: build.query<FixedAndVariableExpensesAsyncResponse, { startDate: string }>({
            query: ({ startDate }) => ({ url: `/get/fixed/and/variable/expenses`, params: { startDate } }),
            providesTags: ['Invoice'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetMinorExpensesByBusinessQuery,
    useGetCustomerPurchaseTrackingQuery,
    useGetProductPurchaseTrackingQuery,
    useGetDashboardInformationQuery,
    useRegisterMinorExpenseMutation,
    useArchivedMinorExpenseMutation,
    useGetMinorShoppingByBusinessQuery,
    useRegisterMinorShoppingMutation,
    useArchivedMinorShoppingMutation,
    useGetClosingOfDayQuery,
    useGetSalesReportQuery,
    useGetCreditReportQuery,
    useGetDeliveryReportQuery,
    useGetInvoiceQuery,
    useDeletePaymentToInvoiceMutation,
    useAddPaymentToInvoiceMutation,
    useChangePaymentMethodMutation,
    useRecordSaleMutation,
    useGetPreviousDaysCreditsQuery,
    useUpdateSaleMutation,
    useGetFiltersForReportsInDateRangeQuery,
    useGetInvoiceReportWithoutDefinedPaymentQuery,
    useArchiveInvoiceMutation,
    useGetFixedAndVariableExpensesQuery,
    useGetMonthlyReportQuery
} = PointOfSale;
