import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import { useURLParams } from "@hook/useURLParams"

interface Props {
    maxAmount: number
}

const ReturnAdvance = ({ maxAmount }:Props) => {
    const { getQuery, delQueries } = useURLParams()
    const open = (getQuery("modal") === "return_advance")

    return(
        <>
            <Dialog maxWidth="xs" fullWidth open={open} onClose={() => delQueries(['modal', 'ref'])} >
                <DialogContent>
                    <Grid container >
                        <Grid xs={12} >
                            <NumberFormat label="Monto a regresar" maxVal={maxAmount} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReturnAdvance