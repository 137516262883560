import React from 'react'
import Box from "@component/Box"
import Card from "@component/Card"
import Typography from "@component/Typography"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { calcProfitPercentage, currencyFormat } from '../../../hooks/useNumberFormat'
import { updateInvoiceTotal } from '../services/PointOfSaleSlice'
import { styled } from '@mui/material/styles'

const CardStyled = styled(Card)(({ theme }) => ({
    [theme.breakpoints.down("sm")] : {
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        marginBottom: '0px !important'
    },
}))

const TypographyTotalize = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("sm")] : {
        fontSize: '1.2rem',
        color: '#FFFFFF'
    }
}))

const PointOfSaleTotalize = () => {
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productList } = PointOfSaleState

    const sale = React.useMemo(() => {
        let totalize:{total: number, profit: number, margin: number | string} = {
            total: 0,
            profit: 0,
            margin: 0
        }
        for( let product of productList ){
            totalize.total += (product.salePrice*product.quantity)
            totalize.profit += ((product.salePrice*product.quantity)-(product.costPrice*product.quantity))
        }
        totalize.margin = calcProfitPercentage(totalize.profit, totalize.total, true)
        return totalize
    },[productList])

    const { total } = sale

    React.useEffect(() => {
        dispatch(updateInvoiceTotal({ total: sale.total, totalProfitCurrency: sale.profit, totalProfitPercentage: sale.margin }))
    },[sale, dispatch])

    return(
        <CardStyled sx={{ marginBottom: 3 }} >
            <Box p={1} display={'flex'} flexDirection={'column'} >
                <TypographyTotalize fontSize={'2.5rem'} fontWeight={'bold'} lineHeight={1} > $ {currencyFormat(total)} </TypographyTotalize>
            </Box>
        </CardStyled>
    )
}

export default PointOfSaleTotalize