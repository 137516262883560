import DataCard from "@component/DataCard"
import { useExpensesBusiness } from "@feature/Business/hooks/useBusiness";
import { currencyFormat } from "@hook/useNumberFormat"
import { useURLParams } from "@hook/useURLParams";
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';

const FixedExpends = () => {
    const { getParameterGroup } = useURLParams()
    const { startDate="" } = getParameterGroup(['startDate'])
    const { resp } = useExpensesBusiness({ startDate })
    return(
        <>
            <DataCard
                headerText="Gastos fijos"
                text={`$ ${currencyFormat((resp?.fixedExpenses??0), true)}`}
                icon={<CurrencyExchangeRounded />}
                typographyProps={{
                    color: (theme) => theme.palette.error.main
                }}
            />
        </>
    )
}

export default FixedExpends