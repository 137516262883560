import React from 'react'
import DataGrid from "@component/DataGrid"
import type { GridColDef } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { loadCustomersToDelete, showSecurityAlertClearClient } from '../services/CustomerSlice';
import Box from '../../../components/Box';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../services/routes';
import IconButton from '../../../components/IconButton';
import VisibilityRounded from '@mui/icons-material/VisibilityRounded';
import Tooltip from '../../../components/Tooltip';
import DataGridCustomHeaderToDelete from '../../../components/DataGridCustomHeaderToDelete';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
import FileDownloadRounded from '@mui/icons-material/FileDownloadRounded';
import SelectCustomerListFields from './SelectCustomerListFields';
import { useURLParams } from '@hook/useURLParams';
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import CustomerAdvance from './CustomerAdvance';
import { modals_map } from '@service/URLParams.map';
import { currencyFormat } from '@hook/useNumberFormat';
import { useGetBusinessCustomers } from '../hooks/useCustomer';

const CustomersList = () => {
    const { addQuery, handleParameterGroups } = useURLParams()
    const { Customers } = useBusinessPermissions()
    const { CustomerState } = useAppSelector(({ CustomerState }) => ({ CustomerState }))
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { customers } = useGetBusinessCustomers()
    const { branch, customersToDelete } = CustomerState


    const columns: GridColDef[] = [
        { minWidth: 140, maxWidth: 140, field: 'paymentAvailable', headerName: 'Anticipo disponible', renderCell: ({ row }) => `$ ${currencyFormat(row.paymentAvailable)}` },
        { field: 'name', headerName: 'Nombre', flex: 1, minWidth: 200 },
        { field: 'address', headerName: 'Dirección', flex: 1 },
        {
            field: ' ', headerName: ' ', maxWidth: 100, align: "center", hideable: !Customers.only_view_and_edit,
            renderCell: ({ row }) => {
                return (
                    <Box display={'flex'} >
                        <Tooltip title="Ver" placement='left' >
                            <IconButton size="small" onClick={() => navigate(Routes.customerData(row._id))} >
                                <VisibilityRounded />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Generar anticipo" placement='left' >
                            <IconButton size="small" onClick={() => handleParameterGroups(["open_modal", "_id"], [modals_map.customer_advance, row._id]) } >
                                <CurrencyExchangeRounded />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            }
        },
    ];

    const handleCustomerSelection = (data: any) => {
        dispatch(loadCustomersToDelete(data))
    }

    const CustomHeader = () => {
        return(
            <>
                <DataGridCustomHeaderToDelete listItems={customersToDelete} texts={["Clientes seleccionados", "Cliente seleccionado"]} title={branch} onClick={() => dispatch(showSecurityAlertClearClient(true))} />
                <Box pt={1} px={2} >
                    <Tooltip title="Descargar lista de clientes" >
                        <IconButton squareButton onClick={() => addQuery({ download: "customer-list" })} >
                            <FileDownloadRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
            </>
        )
    }

    React.useEffect(() => {
        return () => {
            dispatch(loadCustomersToDelete([]))
        }
    }, [dispatch])
    return (
        <>
            <DataGrid
                slots={{
                    toolbar: CustomHeader
                }}
                rows={customers}
                columns={columns}
                onRowSelectionModelChange={handleCustomerSelection}
                checkboxSelection={Customers.delete_information}
            />
            <SelectCustomerListFields />
            <CustomerAdvance />
        </>
    )
}

export default CustomersList