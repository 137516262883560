import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useGetFiltersForReportsInDateRangeQuery, useGetSalesReportQuery } from '../../../api/querys/PointOfSale';
import Box from '../../../components/Box';
import ClippedDrawer from '../../../components/ClippedDrawner';
import DatePicker from '../../../components/DatePicker';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { updateEndDate, updateStartDate, updateCustomers, updateInvoiceNumbers, updateSellers, updateBusinessesType, updateZones, updateBranches, updateGroupBy, updateNeighborhood, updateCity, updateCustomerDocumentNumber, } from '../services/ReportOfTheDaySlice';
import Tooltip from '../../../components/Tooltip';
import IconButton from '../../../components/IconButton';
import { useTranslation } from 'react-i18next';
import FilterReportInput from './FilterReportInput';
import { transformArrayToString } from '../../../services/parseData';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import { useSheet } from '../../../hooks/useSheet';
import { useBusinessPermissions } from '../../../hooks/usePermissions';

const ReportFilters = () => {
    const { ReportOfTheDay } = useBusinessPermissions()
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { useCreateSheet } = useSheet();
    const { ReportOfTheDayState } = useAppSelector(({ ReportOfTheDayState }) => ({
        ReportOfTheDayState,
    }));
    const {
        initDate,
        endDate,
        groupById,
        userId: userIdState,
        customerId: customerIdState,
        invoiceNumbers: invoiceNumbersState,
        businessTypeId: businessTypeIdState,
        zoneId: zoneIdState,
        branchId: branchIdState,
        neighborhoodId: neighborhoodState,
        cityId: cityState
    } = ReportOfTheDayState;

    const { data } = useGetSalesReportQuery({
        initDate,
        endDate,
        userId: userIdState,
        customerId: customerIdState,
        invoiceNumbers: invoiceNumbersState,
        businessTypeId: businessTypeIdState,
        zoneId: zoneIdState,
        branchId: branchIdState,
        neighborhoodId: neighborhoodState,
        cityId: cityState,
        groupById,
    });

    const dataTable = data!?.payload?.group ?? [];

    const { data: filterData } = useGetFiltersForReportsInDateRangeQuery({
        initDate,
        endDate,
    });

    const { customers = [], invoiceNumbers = [], sellers = [], businessesType = [], zones = [], branches = [], neighborhoods = [], cities = [], customerDocumentNumber=[] } = filterData?.payload ?? [];
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };

    const transformDataTable = useCallback(() => {
        let result = [];

        for (let data of dataTable) {
            result.push([data?._id.name]);

            for (let i of data.result) {
                result.push([
                    i.customer,
                    `$ ${currencyFormat(i.total)}`,
                    `#R${i.consecutive}`,
                ]);
            }
            result.push(['']);
        }

        return result;
    }, [dataTable]);

    const HandleDownloadExcel = () => {
        const rows: any[] = transformDataTable();
        useCreateSheet({
            rows,
            fileName: `I${dayjs().format('DDMMYYYYHHmmss')}.xlsx`,
            name: t('reports')!,
            arrayCollection: true,
        });
    };

    const groupByFilters = [
        { name: t('branch'), _id: 1, param: 'branch' },
        { name: t('zone'), _id: 2, param: 'zone' },
        { name: t('neighborhood'), _id: 3, param: 'neighborhood' },
        { name: t('city'), _id: 4, param: 'city' },
        { name: t('businessType'), _id: 5, param: 'businessType' },
    ];

    const transformInvoiceNumberData = invoiceNumbers?.map(
        (props: { name: number; _id: string }) => {
            return {
                name: String(props.name),
                _id: props._id,
            };
        }
    );

    return (
        <>
            {ReportOfTheDay.filter_reports_by_invoice_values &&
                <ClippedDrawer
                    onOpen={handleOpen}
                    open={open}
                >
                    <FilterReportInput
                        label={t('invoiceNumber')!}
                        options={transformInvoiceNumberData}
                        onChange={(_, value: any) => {
                            dispatch(
                                updateInvoiceNumbers(transformArrayToString(value, 'name') ?? '')
                            );
                        }}
                        multiple
                    />
                    <FilterReportInput
                        label={t('customers')!}
                        options={customers}
                        onChange={(_, value: any) => {
                            dispatch(updateCustomers(value?._id ?? ''));
                        }}
                    />
                    <FilterReportInput
                        label={t('Identificación del cliente')!}
                        options={customerDocumentNumber}
                        onChange={(_, value: any) => {
                            dispatch(updateCustomerDocumentNumber(value?.name ?? ''));
                        }}
                    />
                    <FilterReportInput
                        label={t('sellers')!}
                        options={sellers}
                        onChange={(_, value: any) => {
                            dispatch(updateSellers(value?._id ?? ''));
                        }}
                    />
                    <FilterReportInput
                        label={t('branches')!}
                        options={branches}
                        onChange={(_, value: any) => {
                            dispatch(updateBranches(transformArrayToString(value) ?? ''));
                        }}
                        multiple
                    />
                    <FilterReportInput
                        label={t('zones')!}
                        options={zones}
                        onChange={(_, value: any) => {
                            dispatch(updateZones(transformArrayToString(value) ?? ''));
                        }}
                        multiple
                    />
                    <FilterReportInput
                        label={t('neighborhoods')!}
                        options={neighborhoods}
                        onChange={(_, value: any) => {
                            dispatch(updateNeighborhood(transformArrayToString(value) ?? ''))
                        }}
                        multiple
                    />
                    <FilterReportInput
                        label={t('cities')!}
                        options={cities}
                        onChange={(_, value: any) => {
                            dispatch(updateCity(transformArrayToString(value) ?? ''))
                        }}
                        multiple
                    />
                    <FilterReportInput
                        label={t('businessesType')!}
                        options={businessesType}
                        onChange={(_, value: any) => {
                            dispatch(updateBusinessesType(transformArrayToString(value) ?? ''));
                        }}
                        multiple
                    />
                    <FilterReportInput
                        label={`${t('groupBy')!}: `}
                        options={groupByFilters || []}
                        onChange={(_, value: any) => {
                            dispatch(updateGroupBy(value?.param ?? ''));
                        }}
                    />
                </ClippedDrawer>
            }
            <Box display='flex' alignItems='center' gap={1} marginBottom={1} >
                {ReportOfTheDay.filter_reports_by_days &&
                    <>
                        <DatePicker
                            label={t('from')!}
                            value={dayjs(initDate).toDate()}
                            onChange={({ value }) =>
                                dispatch(updateStartDate(dayjs(value).format('MM-DD-YYYY')))
                            }
                        />
                        <DatePicker
                            label={t('to')!}
                            value={dayjs(endDate).toDate()}
                            onChange={({ value }) =>
                                dispatch(updateEndDate(dayjs(value).format('MM-DD-YYYY')))
                            }
                        />
                    </>
                }

                {ReportOfTheDay.filter_reports_by_invoice_values &&
                    <Box>
                        <Tooltip title={t('filters')}>
                            <IconButton squareButton onClick={handleOpen} >
                                <FilterListRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
                {ReportOfTheDay.download_template_of_the_report_of_the_day &&
                    <>
                        {groupById && (
                            <Box>
                                <Tooltip title={t('downloadExcel')}>
                                    <IconButton
                                        squareButton
                                        onClick={HandleDownloadExcel}
                                    >
                                        <SimCardDownloadRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    </>
                }

            </Box>
        </>
    );
};

export default ReportFilters;
