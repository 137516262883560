import { useNavigate } from "react-router-dom"
import { useGetBusinessCustomersQuery } from "../../../api/querys/Customer"
import Autocomplete from "@component/Autocomplete"
import Box from "@component/Box"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import Routes from "../../../services/routes"
import AddRounded from '@mui/icons-material/AddRounded';
import type { CustomerDataProps } from "@interface/Customer"
import { displayFormToCreateCustomer } from "../services/CustomerSlice"
import { useAppDispatch } from "../../../hooks/state"
import { useTranslation } from "react-i18next"
import { useBusinessPermissions } from "../../../hooks/usePermissions"
import Grid from "@component/Grid"
import Checkbox from "@component/Checkbox"
import { useURLParams } from "@hook/useURLParams"
import { URLParameters_map } from "@service/URLParams.map"

interface Props extends Pick<React.ComponentProps<typeof Autocomplete>, "sx" | "onChange"> {
    list?: CustomerDataProps[]
    addButton?: boolean
}

const SearchCustomer = ({ addButton=true, list=[], sx, onChange }:Props) => {
    const { handleActionParameter, getQuery } = useURLParams()
    const availableAdvance = getQuery(URLParameters_map.advance) === "available"
    const { Customers } = useBusinessPermissions()
    const { t } = useTranslation()
    const { data } = useGetBusinessCustomersQuery()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const customers = data?.payload?.customers?.results??[]
    const handleCustomerSelection = (_:any, customer:any) => {
        if(Boolean(customer)) navigate(Routes.customerData(customer._id))
    }

    const handleCreateCustomer = () => {
        dispatch(displayFormToCreateCustomer(true))
    } 

    return(
        <Grid container spacing={1} >
            <Grid xs={12} >
                <Box display={'flex'} justifyContent={'end'} alignItems={'center'} sx={sx} >
                    {Customers.only_view_and_edit &&
                        <Autocomplete
                            onChange={ (onChange === undefined) ? handleCustomerSelection : onChange}
                            label={t('findCustomer')!}
                            options={customers}
                        />
                    }
                    {addButton &&
                        <Box mx={1} >
                            { Customers.create_information &&
                                <Tooltip title="Crear nuevo cliente" placement="top-start" >
                                    <IconButton squareButton onClick={handleCreateCustomer} >
                                        <AddRounded sx={{ fontSize: "2rem", fontWeight: 'bold' }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    }
                </Box>
            </Grid>
            <Grid xs={12} >
                <Checkbox checked={availableAdvance} label="Clientes con anticipos" onChange={() => handleActionParameter("advance", "available")} />
            </Grid>
        </Grid>
    )
}

export default SearchCustomer