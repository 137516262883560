import Box from "@component/Box";
import DataCard from "@component/DataCard"
import Typography from "@component/Typography";
import { useGetMonthlyReport } from "@feature/Business/hooks/useBusiness"
import { currencyFormat } from "@hook/useNumberFormat"
import MonetizationOnRounded from '@mui/icons-material/MonetizationOnRounded';

const MonthlySalesReport = () => {
    const { totalSale, totalCurrency, totalPercentage } = useGetMonthlyReport()
    const Summary = () => {
        return(
            <>
                <Box display={'flex'} flexDirection={'column'} >
                    <Typography textAlign={'center'} fontWeight={'bold'} fontSize={'1.3rem'} sx={{ color: (theme) => theme.palette.success.main }} >
                        $ {currencyFormat(totalSale)}
                    </Typography>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={2} >
                        <Typography fontWeight={'500'} fontSize={'1rem'} > $ {currencyFormat(totalCurrency)} </Typography>
                        <Typography fontWeight={'500'} fontSize={'1rem'} > {totalPercentage} % </Typography>
                    </Box>
                </Box>
            </>
        )
    }
    return(
        <>
            <DataCard
                headerText="Venta del mes"
                text={`$ ${currencyFormat(totalSale)}`}
                icon={<MonetizationOnRounded />}
                slots={{
                    cardBody: <Summary />
                }}
            />
        </>
    )
}

export default MonthlySalesReport