import React from 'react';
import { Card as CardMUI, CardHeader as CardHeaderMUI,  CardProps, CardHeaderProps } from '@mui/material'

type Props = Pick<CardProps, "variant" | "elevation" | "sx" | "children" | "onClick" | "id" >

const Card = React.forwardRef<HTMLDivElement, Props>(({ children, elevation=0, variant="outlined", sx={}, ...rest }, ref) => {
    return(
        <CardMUI ref={ref} elevation={elevation} variant={variant} sx={{padding: 1, ...sx}} {...rest} >
            {children}
        </CardMUI>
    )
})

export const CardHeader = ({...props}:Pick<CardHeaderProps, "children" | "sx" | "avatar" | "action" | "title" | "subheader">) => <CardHeaderMUI {...props} />



export default Card