import { currencyFormat } from "@hook/useNumberFormat"
import { useGetBusinessFinancialStatement } from "@feature/Business/hooks/useBusiness"
import DataCard from "@component/DataCard"
import TrendingDownRounded from '@mui/icons-material/TrendingDownRounded';
import { useURLParams } from "@hook/useURLParams";

const BusinessLiabilities = () => {
    const { getParameterGroup } = useURLParams()
    const { startDate="" } = getParameterGroup(["startDate"])
    const { liability } = useGetBusinessFinancialStatement({ startDate })

    return (
        <>
            <DataCard
                headerText="Pasivos"
                text={`$ ${currencyFormat(liability, true)}`}
                icon={<TrendingDownRounded />}
                typographyProps={{
                    sx:{
                        color: (theme) => theme.palette.error.main
                    }
                }}
            />
        </>
    )
}

export default BusinessLiabilities