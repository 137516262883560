import DataCard from "@component/DataCard"
import { useExpensesBusiness } from "@feature/Business/hooks/useBusiness";
import { currencyFormat } from "@hook/useNumberFormat"
import { useURLParams } from "@hook/useURLParams";
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import Routes from "@service/routes";
import { useNavigate } from "react-router-dom";

const VariableExpends = () => {
    const { getParameterGroup } = useURLParams()
    const { startDate="" } = getParameterGroup(['startDate'])
    const { resp } = useExpensesBusiness({ startDate })
    const navigate = useNavigate()
    return(
        <>
            <DataCard
                onClick={() => navigate(Routes.indexExpenses()) }
                headerText="Gastos variables"
                text={`$ ${currencyFormat((resp?.variableExpense??0), true)}`}
                icon={<CurrencyExchangeRounded />}
                typographyProps={{
                    color: (theme) => theme.palette.error.main
                }}
            />
        </>
    )
}

export default VariableExpends