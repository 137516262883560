import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {  useGetAccurateCustomerInformationQuery, useGetBusinessCustomersQuery, useGetCustomerQuery, useRecordCustomerAdvanceMutation, useRegisterCustomerMutation,  useUpdateCustomerAdvanceMutation,  useUpdateCustomerMutation  } from '@query/Customer';

import { CustomerAdvanceFormProps, CustomerDataForm, CustomerDataResponse, UpdateCustomerAdvanceProps, businessCustomersDataResponse } from '@interface/Customer';
import { useAppDispatch } from '../../../hooks/state';
import { displayFormToCreateCustomer } from '../services/CustomerSlice';
import { isSuccessResponse } from '@service/AnswersServices';
import { isErrorResponse } from '@service/AnswersServices';
import { useURLParams } from '@hook/useURLParams';
import { URLParameters_map } from '@service/URLParams.map';
import { useParams } from 'react-router-dom';

export const useCustomer = () => {
    const { handleParameterGroups, getQuery, delQueries } = useURLParams()
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch()

  const [register, { isLoading: registerIsLoading }] = useRegisterCustomerMutation();
  const [update, { isLoading: updateIsLoading }] = useUpdateCustomerMutation();
  const [ recordCustomerAdvanceMutation, { isLoading:isLoadingRecordCustomerAdvanceMutation } ] = useRecordCustomerAdvanceMutation()
    const [ updateCustomerAdvanceMutation, { isLoading:isLoadingUpdateCustomerAdvanceMutation } ] = useUpdateCustomerAdvanceMutation()

  const registerMutation = async (clearFields: () => void, onCreateFastCustomer: any,  payload: CustomerDataForm) => {
      if (Boolean(!payload._id) && (
        payload.name?.trim() === ''
      )) {
        enqueueSnackbar(t('customerNameIsARequiredField'), {
          variant: 'warning',
        });
        return;
      }
      if (Boolean(!payload._id) && (payload.name?.trim() === '')) {
        enqueueSnackbar(
          t(
            'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
          )!,
          { variant: 'error' }
        );
        return;
      }
      const resp = Boolean(payload._id)
        ? await update({...payload, _id: payload._id}).unwrap()
        : await register(payload).unwrap();
      if (resp?.status === 1) {
        const message = Boolean(payload._id)
          ? t('theCustomerWasSuccessfullyUpdated')
          : t('theCustomerWasRegisteredSuccessfully')!;
          if (onCreateFastCustomer !== undefined) {
                onCreateFastCustomer(resp.payload);
            }
        enqueueSnackbar(message, { variant: 'success' });
        dispatch(displayFormToCreateCustomer(false))
        clearFields()
      } else {
        enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, {
          variant: 'error',
        });
      }
    }
    const recordCustomerAdvance = async (params:CustomerAdvanceFormProps) => {
        try {
            const ref = getQuery("ref")
            if( ref !== null && ref !== undefined ){
                params.refId = ref
            }else{
                isErrorResponse("Parece que a ocurrido un error, falta referencia")
            }
            let resp = await recordCustomerAdvanceMutation(params).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse("Anticipo registrado con éxito")
                handleParameterGroups(["open_modal", "_id"], ["", ""])
            }else{
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updateCustomerAdvance = async (payload:UpdateCustomerAdvanceProps) => {
        try {
            const resp = await updateCustomerAdvanceMutation(payload).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse("Se actualizo el anticipo satisfactoriamente")
                delQueries(['ref', 'field'])
            }else{
                isErrorResponse()
            }
        } catch (error) {
            throw error
        }
    }
  
  return {
    registerMutation,
    registerIsLoading,
    updateIsLoading,
    recordCustomerAdvance,
    isLoadingRecordCustomerAdvanceMutation,
    isLoadingUpdateCustomerAdvanceMutation,
    updateCustomerAdvance
  };
};

export const useGetAccurateCustomerInformation = (fields:string) => {
    const { t } = useTranslation()
    const { isLoading, data } = useGetAccurateCustomerInformationQuery({fields})
    const list = data?.payload??[]
    let listForTheBook = structuredClone(list)
    listForTheBook = listForTheBook.map((n:any) => {
        delete n._id
        return n
    })
    let fieldsToResponse = list.length >= 1 ? Object.keys(listForTheBook[0]) : []
    fieldsToResponse = fieldsToResponse.map(n => t(n))
    return {
        list,
        isLoading,
        listForTheBook: listForTheBook,
        fieldsToResponse
    }
}

export const useGetBusinessCustomers = () => {
    const { data, isFetching } = useGetBusinessCustomersQuery()
    const { getQuery } = useURLParams()
    const availableAdvance = getQuery(URLParameters_map.advance) === "available"
    const resp = data?.payload??{} as businessCustomersDataResponse['payload']
    let customers = {...resp}?.customers?.results??[] as businessCustomersDataResponse['payload']['customers']['results']
    let customersGroupByBranch = {...resp}?.customersGroupByBranch??[] as businessCustomersDataResponse['payload']['customersGroupByBranch']

    if( availableAdvance ){
        customers = customers.filter(n => n.paymentAvailable >= 1)
        customersGroupByBranch = customersGroupByBranch.map(n => ({...n, results: n.results.filter(x => x.paymentAvailable >= 1)}))
    }

    let totalAdvances = customers.reduce((acc, params) => (params?.paymentAvailable??0)+acc,0)

    return {
        resp,
        customers,
        customersGroupByBranch,
        isLoading: isFetching,
        totalAdvances
    }
}

export const useGetCustomer = () => {
    const { customerId } = useParams()
    const { data, isLoading } = useGetCustomerQuery(customerId as string)
    const customer = data?.payload??{} as CustomerDataResponse['payload']
    const advances = customer?.advances??[] as CustomerDataResponse['payload']['advances']
    const totalAdvances = advances.reduce((acc, params) => (params?.amount??0)+acc,0)
    return {
        customer,
        isLoading,
        totalAdvances,
        advances
    }
}